import * as $ from 'jquery';

declare global {
    interface Window {
        dataLayer: any;
    }
}
let dataLayer = window.dataLayer;
//Service selection (Expertise)
$('.service-card__body').on('click', function (e) {
    let _this = $(this)
    dataLayer.push({
        'event': 'Service selection',
        'eventAction': 'click',
        'eventLabel': `${_this.find('h5')[0].innerText}`,
    })
})

//Top content type content consumed/insights
$(document).on('click', '.article-card__wrapper', function (e) {
    let _this = $(this)
    dataLayer.push({
        'event': 'Content type',
        'eventCategory': 'Content hub selection',
        'eventAction': 'click',
        'eventLabel': `${_this.find('.article-card__body').find('h5')[0].innerText}`,
    })
});

//get in touch icons  Top people + ‘Get in touch’ events

$(document).on('click', '.contacts-card__wrapper', function (e) {
    let _this = $(this)
    $('.contacts-card__icons__phone').on('click', function () {
        dataLayer.push({
            'event': ' Personnel - Get in touch ',
            'eventCategory': 'Personnel - Get in touch',
            'eventAction': 'click',
            'eventLabel': `${_this.find('.contacts-card__body').find('h4')[0].innerText} - phone`,
        })
    })
    $('.contacts-card__icons__email').on('click', function () {
        dataLayer.push({
            'event': ' Personnel - Get in touch ',
            'eventCategory': 'Personnel - Get in touch',
            'eventAction': 'click',
            'eventLabel': `${_this.find('.contacts-card__body').find('h4')[0].innerText} - email`,
        })
    })
    $('.contacts-card__icons__linkdin').on('click', function () {
        dataLayer.push({
            'event': ' Personnel - Get in touch ',
            'eventCategory': 'Personnel - Get in touch',
            'eventAction': 'click',
            'eventLabel': `${_this.find('.contacts-card__body').find('h4')[0].innerText} - Linked-in`,
        })
    })

});

//Get in touch widget usage
$('.footer__newsletter__links').on('click', function (e) {
    let _this = $(this)
    $('.button').on('click', function () {
        dataLayer.push({
            'event': ' Contact Us Widget Usage ',
            'eventCategory': 'Contact Us Widget Usage ',
            'eventAction': 'click',
            'eventLabel': `${window.location.href}`,
        })
    })
})

// search input value
$('.search-result__input--btn').on('click', function (e) {
    dataLayer.push({
        'event': 'Search Usage',
        'eventCategory': 'Search Usage ',
        'eventAction': 'click',
        'eventLabel': `${$('.search-result__input-field').val()}`,
    })

})

//contact form
$('.contact-us__form').find('.btn').on('click', function (e) {
    if ($('.contact-us__form:has(".field-validation-error")').length) {
        return
    }
    else {
        dataLayer.push({
            'event': 'Contact Us Completion',
            'eventCategory': 'Contact Us form completion',
            'eventAction': 'click',
        })
    }
})

// Menu usage

$('.sidebar__list-item__level-one--btn').on('click', function (e) {
    let innerText = $(this).find('span,a')[0].innerText
    dataLayer.push({
        'event': 'Menu usage',
        'eventAction': 'click',
        'eventCategory': `${innerText}`,
    })

})
$('.sidebar__list-item__level-one').find('a').on('click', function (e) {
    let innerText = $(this)[0].innerText
    dataLayer.push({
        'event': 'Menu usage',
        'eventAction': 'click',
        'eventCategory': `${innerText}`,
    })

})
$('.sidebar__list-item__level-two--btn').on('click', function () {
    let innerText = $(this).find('span')[0].innerText
    let parentText = $(this).parents('.sidebar__list-item__level-one').find('button')[0].innerText;
    dataLayer.push({
        'event': 'Menu usage',
        'eventAction': 'click',
        'eventCategory': `${parentText} -> ${innerText}`,
    })
})
$('.sidebar__list-item__level-three').on('click', function (e) {
    let innerText = $(this)[0].innerText;
    let parentText = $(this).parents('.sidebar__list-item__level-two').find('button')[0].innerText;
    let superParentText = $(this).parents('.sidebar__list-item__level-one').find('button')[0].innerText;
    dataLayer.push({
        'event': 'Menu usage',
        'eventAction': 'click',
        'eventCategory': `${superParentText} -> ${parentText} -> ${innerText}`
    })
})
