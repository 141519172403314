import * as $ from 'jquery';

class slideToggle {
  
  private slideToggleButtonElements: NodeListOf<HTMLElement> = null;

  init = () => {

    this.slideToggleButtonElements = document.querySelectorAll('.js-slide-toggle-btn');

    if (!this.slideToggleButtonElements) {
      return;
    }

    this.bindListeners();
  };

  private bindListeners() {
    Array.prototype.map.call(this.slideToggleButtonElements, (element) => {
      element.addEventListener('click', (e: MouseEvent) => this.handleSlideToggle(e));
    });
  }

  handleSlideToggle(e: MouseEvent) {
    const parentElement = $(e.currentTarget).parents(".js-slide-toggle");
    $(parentElement).find(".js-slide-toggle-hidden").slideToggle();
    const toggleElement = $(e.currentTarget).find(".js-slide-toggle-text");
    const moreText = toggleElement.data("moretext");
    const lessText = toggleElement.data("lesstext");
    if(toggleElement.text().trim() === moreText) {
      toggleElement.text(lessText);
      $(e.currentTarget).parent().addClass("js-slide-toggle--active")
    } else {
      toggleElement.text(moreText)
      $(e.currentTarget).parent().removeClass("js-slide-toggle--active")
    }
  }

}

const SlideToggleInstance = new slideToggle();
SlideToggleInstance.init();

export { slideToggle, SlideToggleInstance };
