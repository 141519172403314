import { Accordion } from './accordion';

class Accordions {
  init = () => {
    const accordionElements: NodeListOf<HTMLDivElement> = document.querySelectorAll('.accordion');
    
    if (!accordionElements.length) {
      return;
    }

    Array.prototype.map.call(accordionElements, (element) => this.createAccordion(element));
  };

  private createAccordion = (accordion) => {
    new Accordion(accordion);
  };
}

const accordionInstance = new Accordions();
accordionInstance.init();

export { Accordions, accordionInstance };
