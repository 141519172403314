import * as $ from 'jquery';

class Accordion {
  private el: HTMLDivElement;
  private accordionHeader: HTMLButtonElement;
  private accordionCloseBtn: HTMLButtonElement;
  private accordionContent: HTMLDivElement;
  private activeClass: string = 'accordion--is-expanded';

  constructor(element) {
    this.el = element;
    this.accordionHeader = this.el.querySelector('.accordion__button');
    this.accordionCloseBtn = this.el.querySelector('.accordion__button--close');
    this.accordionContent = this.el.querySelector('.accordion__content');
    this.accordionCloseBtn = this.el.querySelector('.accordion__button--close') ? this.el.querySelector('.accordion__button--close') : null;
    
    this.bindListeners();
  }

  bindListeners = () => {
    if(this.accordionCloseBtn) {
      this.accordionCloseBtn.addEventListener('mousedown', this.handleAccordionToggleClick);
    }
    this.accordionHeader.addEventListener('mousedown', this.handleAccordionToggleClick);
    this.accordionHeader.addEventListener('keydown', this.handleAccordionToggleKeyDown);
  };

  private handleAccordionToggleClick = (e: MouseEvent) => {
    e.preventDefault();
    this.toggleAccordion();
  };

  private handleAccordionToggleKeyDown = (e: KeyboardEvent) => {
    switch (e.code) {
      case 'Enter':
        e.preventDefault();
        this.toggleAccordion();
        break;
      case 'Space':
        e.preventDefault();
        this.toggleAccordion();
        break;
      case 'ArrowUp':
        e.preventDefault();
        this.closeAccordion();
        break;
      case 'ArrowDown':
        e.preventDefault();
        this.openAccordion();
        break;
    }
  };

  private toggleAccordion = () => {
    if (this.el.classList.contains(this.activeClass)) {
      this.closeAccordion()
      return;
    }

    this.openAccordion();
    return;
  };

  private openAccordion = () => {
    this.el.classList.add(this.activeClass);
    $(this.accordionContent).slideDown();
    return;
  };

  private closeAccordion = () => {
    this.el.classList.remove(this.activeClass);
    $(this.accordionContent).slideUp();
    return;
  };
}

export { Accordion };
