import * as $ from 'jquery';

class header {
  init = () => {

    $(".header__menu__hamburger, .sidebar__panels--close button").on("click", function(e) {
      e.stopPropagation();
      $(".header__overlay").toggleClass('header__overlay-isactive');
      $('body').toggleClass("header-active");
      $(".sidebar__panels").toggleClass("sidebar__panels--is-visible");
      $(".sidebar__panel--top-level").removeClass("sidebar__panel--level-two-active").removeClass("sidebar__panel--level-three-active");
      $(".sidebar__panel--sub").removeClass("sidebar__panel--second-level--active").removeClass("sidebar__panel--third-level--active");
    })
    
    $(".sidebar__list-item__level-one--btn").on("click", function(e) {
      e.stopPropagation()
      const activeState = $(this).siblings(".sidebar__panel--second-level").hasClass("sidebar__panel--second-level--active");
      $(".sidebar__panel--second-level").removeClass("sidebar__panel--second-level--active")
      $(".sidebar__panel--third-level").removeClass("sidebar__panel--third-level--active");
      if(!activeState) {
        $(this).siblings(".sidebar__panel--second-level").addClass("sidebar__panel--second-level--active");
      }
      $(".sidebar__panel--top-level").toggleClass("sidebar__panel--level-two-active");
    })
    
    $(".sidebar__list-item__level-two--btn").on("click", function(e) {
      e.stopPropagation()
      const activeState = $(this).siblings(".sidebar__panel--third-level").hasClass("sidebar__panel--third-level--active");
      $(".sidebar__panel--third-level").removeClass("sidebar__panel--third-level--active");
      if(!activeState) {
        $(this).siblings(".sidebar__panel--third-level").addClass("sidebar__panel--third-level--active");
      }
      $(".sidebar__panel--top-level").toggleClass("sidebar__panel--level-three-active");
    })
    
    // Level 02 Back button
    $(".sidebar__list__level-two--back-btn").on("click", function(e) {
      e.stopPropagation()
      $(this).parents(".sidebar__panel--sub").removeClass("sidebar__panel--second-level--active")
      $(".sidebar__panel--top-level").removeClass("sidebar__panel--level-two-active");
      $(".sidebar__list__level-three--back-btn").parents(".sidebar__panel--sub").removeClass("sidebar__panel--third-level--active")
      $(".sidebar__panel--top-level").removeClass("sidebar__panel--level-three-active");
    })
    
    // Level 03 Back button
    $(".sidebar__list__level-three--back-btn").on("click", function(e) {
      e.stopPropagation()
      $(this).parents(".sidebar__panel--sub").removeClass("sidebar__panel--third-level--active")
      $(".sidebar__panel--top-level").removeClass("sidebar__panel--level-three-active");
    })
    
    $('.header__overlay').on("click",function(e) {
      e.stopPropagation()
      $(".sidebar__panels").removeClass("sidebar__panels--is-visible");
      $('body').removeClass("header-active");
      $(".sidebar__panel--top-level").removeClass("sidebar__panel--level-two-active").removeClass("sidebar__panel--level-three-active");
      $(".sidebar__panel--sub").removeClass("sidebar__panel--second-level--active").removeClass("sidebar__panel--third-level--active");
      $('.header__overlay').removeClass('header__overlay-isactive')
    })
  }
}

const HeaderInstance = new header();
HeaderInstance.init();

export { header, HeaderInstance };