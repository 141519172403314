class socialMediaShare {
  
  private socialMediaShareButtonElements: NodeListOf<HTMLElement> = null;

  init = () => {

    this.socialMediaShareButtonElements = document.querySelectorAll('.js-social-media-share');

    if (!this.socialMediaShareButtonElements) {
      return;
    }

    this.bindListeners();
  };

  private bindListeners() {
    Array.prototype.map.call(this.socialMediaShareButtonElements, (element) => {
      element.addEventListener('click', (e: MouseEvent) => this.handleSocialMediaClick(e));
    });
  }

  handleFacebookClick() {
    const windowTarget = "http://www.facebook.com/sharer.php?u=";
    const windowName =  "facebook share";
    this.handleSocialMediaShare(windowTarget,windowName)
  }

  handleTwitterClick() {
    const windowTarget = "https://twitter.com/intent/tweet?text=";
    const windowName =  "twitter";
    this.handleSocialMediaShare(windowTarget,windowName)
  }

  handleLinkdinClick() {
    const windowTarget = "http://www.linkedin.com/shareArticle?mini=true&url=";
    const windowName =  "LinkedIn";
    this.handleSocialMediaShare(windowTarget,windowName)
  }

  handleSocialMediaShare(windowTarget: string, windowName: string) {
    const windowParams = "left=0,top=0,width=650,height=420,personalbar=0,toolbar=0,scrollbars=0,resizable=0";
    const currentPageURL = window.location.href;
    window.open(windowTarget+encodeURIComponent(currentPageURL),windowName,windowParams)
  }

  handleSocialMediaClick(e: MouseEvent) {
    const elementMediaType = (<HTMLButtonElement>e.currentTarget).getAttribute("data-media");
    if(elementMediaType === "facebook") {
      this.handleFacebookClick()
    } else if(elementMediaType === "twitter") {
      this.handleTwitterClick()
    } else {
      this.handleLinkdinClick()
    }
  }
}

const SocialMediaShareInstance = new socialMediaShare();
SocialMediaShareInstance.init();

export { socialMediaShare, SocialMediaShareInstance };
