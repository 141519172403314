import * as $ from 'jquery';

class InPageNav {
  
  private pageNavElements: NodeListOf<HTMLElement> = null;

  init = () => {

    this.pageNavElements = document.querySelectorAll('.js-in-page-nav');

    if (!this.pageNavElements) {
      return;
    }

    this.bindListeners();
  };

  private bindListeners() {
    Array.prototype.map.call(this.pageNavElements, (element) => {
      element.addEventListener('click', (e: MouseEvent) => this.handleScrollToElement((<HTMLAnchorElement>e.currentTarget).getAttribute("href")));
    });
  }

  private handleScrollToElement(elementToScroll) {
    $('html, body').animate({
      scrollTop: $(elementToScroll).offset().top
    }, 500);
  }
}

const InPageNavInstance = new InPageNav();
InPageNavInstance.init();

export { InPageNav, InPageNavInstance };