import * as $ from 'jquery';
import 'slick-carousel';

class insightsCarousel {

  private insightsCarouselElements: NodeListOf<HTMLElement> = null;

  init = () => {

    this.insightsCarouselElements = document.querySelectorAll('.js-insights-carousel');
    if (!this.insightsCarouselElements) {
      return;
    }

    this.bindListeners();
  };

  private bindListeners() {
    Array.prototype.map.call(this.insightsCarouselElements, (element) => {
      this.handleSlickInit(element)
    });
  }

  handleSlickInit(el: MouseEvent) {
    const $el = $(el);
    const arrowIcon = '<span class="button__arrow button__arrow--first"><svg viewbox="0 0 15 15" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" focusable="false"> <use xlink:href="/public/assets/icons/spritemap.svg#upward-right-lg"></use></svg></span><span class=" button__arrow button__arrow--second"><svg viewbox="0 0 15 15" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" focusable="false"> <use xlink:href="/public/assets/icons/spritemap.svg#upward-right-lg"></use></svg></span>';
    const slickSettings : JQuerySlickOptions = {
      dots: true,
      prevArrow: `<button class="slick-prev slick-arrow" aria-label="Previous" type="button">${arrowIcon}</button>`,
      nextArrow: `<button class="slick-next slick-arrow" aria-label="Next" type="button">${arrowIcon}</button>`,
      variableWidth: true,
      adaptiveHeight: true,
      infinite: false,
      slidesToScroll: 1,
    }

    $el.slick(slickSettings);

    const totalSlides = $el.find(".slick-dots li").length
    // We have two carousel variants, with progress bar and without in mobile view.
    // check if .js-insights-carousel-no-pbar is present then don't include the swipe event
    if (!$el.hasClass("js-insights-carousel-no-pbar")) {
      $el.on('afterChange', function (event, slick) {
        const currentSlide = slick.currentSlide + 1;
        handleProgressBarWidthAnimation((currentSlide / totalSlides) * 100)
      });
    }

    const handleProgressBarWidthAnimation = (percentageWidth) => {
      $el.siblings(".insights-carousel__progress-bar").children(".insights-carousel__progress-bar__tracker").css("width", `${percentageWidth}%`)
    }

    handleProgressBarWidthAnimation((1 / totalSlides) * 100)
  }
}

const InsightsCarouselInstance = new insightsCarousel();
InsightsCarouselInstance.init();

export { insightsCarousel, InsightsCarouselInstance };
